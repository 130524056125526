import { useEffect, useState, useContext } from "react";
import { React } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { RequiredRegistrationTextbox } from "../../components/registration/RequiredRegistrationTextbox";
import { RegistrationSelectionDropdown } from '../../components/registration/RegistrationSelectionDropdown'
import { useAuthContext } from "../../infrastructure/AuthContext";
import { VerifyUserAccountDisabled } from "./VerifyUserAccountDisabled";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext } from '../../infrastructure/Contexts';
import { MyAccountValidationQuestions } from "../../infrastructure/Constants";
//import { Language } from '../../infrastructure/Languages';

import './VerifyUserAccountPage.css'

export const VerifyUserAccountPage = ({ onError, submitError }) => {

    //const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const navigate = useNavigate();
    const location = useLocation();
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const estimateDataAccess = new SecureApiDataAccess("/api/estimates");
    const { getLoggedUser } = useAuthContext();

    const [user, setUser] = useState();
    const [myAccountRegistrationEnabled, setMyAccountRegistrationEnabled] = useState(false);
    const [ready, setReady] = useState(false);

    if (!location.state || !location.state.culture)
        console.log("missing culture parameter, registration is not possible");
    const culture = location?.state?.culture;

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            salesforceGlobalAccountNumber: null,
            validationQuestion: null,
            validationAnswer: null
        },
    });

    useEffect(() => {
        const init = async () => {
            var user = await getLoggedUser();
            if (user.salesforceAccountId)
                navigate('/myorders');  // user does not need to be linked to a salesforce account
            setUser(user);

            // check if my account registration is enabled
            if (culture)
                await estimateDataAccess.get(`/feature-settings/${culture}`)
                    .then(response => {
                        setMyAccountRegistrationEnabled(response.data.myAccountRegistrationEnabled);
                    })
                    .finally(setReady(true));
        }
        init();
    }, []);

    const verifyUserIsLinkedToSalesforceAccount = async () => {
        var user = await getLoggedUser();
        setUser(user);
        return !!user.salesforceAccountId;
    }

    /*
    const onFormError = (errors, e) => {
    };
    */

    const onValidationTypeChanged = (e) => {
        setValue("validationQuestion", e.target.selectedIndex);
    }

    const onSubmitForm = (data, e) => {
        loader.show();
        var request = {
            culture: culture,
            userId: user.userId,
            salesforceGlobalAccountNumber: data.salesforceGlobalAccountNumber,
            validationQuestion: data.validationQuestion,
            validationAnswer: data.validationAnswer
        };
        userDataAccess.put('/account/salesforce', request).then(response => {
            verifyUserIsLinkedToSalesforceAccount().then(response => {
                if (response)
                    navigate('/myorders');
            });
        }).finally(loader.hide());
    };

    return (
        <div className="registration-page">
            {
                ready &&
                <AuthenticatedTemplate>
                    {

                        !myAccountRegistrationEnabled ?
                            <VerifyUserAccountDisabled />
                            :
                            <>
                                <h3>User Account Registration</h3>
                                <form className="registration-container" onSubmit={handleSubmit(onSubmitForm, null)}>
                                    <div className="registration-grid-item">
                                        <RequiredRegistrationTextbox
                                            control={control}
                                            placeHolder={"Salesforce Global Account Number" /*language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', '', 'Label')*/}
                                            label={"Salesforce Global Account Number" /*language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', '', 'Label')*/}
                                            rules={{ required: "Salesforce Global Account Number is required" /*language.translateLabel('6a5500e5-d62a-40e0-9efa-0a10e5377643', 'A first name is required', 'Label')*/ }}
                                            name="salesforceGlobalAccountNumber"
                                        />
                                    </div>
                                    <div className="registration-grid-item" />
                                    <div className="registration-grid-item">
                                        <RegistrationSelectionDropdown
                                            data={MyAccountValidationQuestions}
                                            control={control}
                                            label={"Validation Question" /*language.translateLabel('', '', 'Label')*/}
                                            rules={{ required: "Validation Question is required" /*language.translateLabel('6a5500e5-d62a-40e0-9efa-0a10e5377643', 'A first name is required', 'Label')*/ }}
                                            name="validationQuestion"
                                            onValueChanged={onValidationTypeChanged}
                                        />
                                    </div>
                                    <div className="registration-grid-item">
                                        <RequiredRegistrationTextbox
                                            control={control}
                                            placeHolder={"Answer" /*language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', '', 'Label')*/}
                                            label={"Answer" /*language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', '', 'Label')*/}
                                            rules={{ required: "An answer is required" /*language.translateLabel('6a5500e5-d62a-40e0-9efa-0a10e5377643', 'A first name is required', 'Label')*/ }}
                                            name="validationAnswer"
                                        />
                                    </div>
                                    {submitError && submitError.length > 0 && (
                                        <div className="registration-notifications">
                                            <ul className="registration-validation-error">
                                                {submitError.map((item, idx) => <li key={idx}>{item}</li>)}
                                            </ul>
                                        </div>
                                    )}
                                    <div className="registration-submit">
                                        <button type="submit" className="btn btn-primary">
                                            Register
                                        </button>
                                    </div>
                                </form>
                            </>
                    }

                </AuthenticatedTemplate>
            }
        </div>
    )
};
