import { React } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

export const Login = () => {

    return (
        <div>
            {
                process.env.REACT_APP_SHOW_REGISTRATION_POPUP &&
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} />
            }
        </div>
    )
}