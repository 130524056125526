import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuthContext } from "../../infrastructure/AuthContext";

export const MyAccountLandingPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { getLoggedUser, openLoginPage, openLoginPopup } = useAuthContext();
    let isAuthenticated = useIsAuthenticated();
    const [errorMessage, setErrorMessage] = useState();

    let region = location?.state?.region;

    useEffect(() => {
        if (isAuthenticated)
            route();
        else
            openLoginPage();            
    }, [isAuthenticated]);

    // route to registration page or myorder page based on user registration status
    const route = async () => {

        var user = await getLoggedUser();
        if (user == null) {
            setErrorMessage("user not found! please login using a valid user");
            return;
        }

        if (user.salesforceAccountId)
            navigate('/myorders')

        else {
            if (region) {
                navigate(`/verification`, {
                    state: {
                        culture: region
                    }
                })
            }
            else {
                console.log("missing region info, account validation is not possible");
            }
        }
    }

    return (
        <div>
            {errorMessage}
        </div >
    )
}