import { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

export const MyAccountPageReachableByWebSite = () => {

    const navigate = useNavigate();
    let { region } = useParams();

    useEffect(() => {
        if (region && navigate)
            navigate('/myaccount', {
                state: {
                    region: region
                }
            });
    }, [navigate, region]);
}