import { useContext } from "react";
import { Language } from "../../infrastructure/Languages";
import { SectionLabel } from "../../core/SectionLabel";
import { SectionTitle } from "../../core/SectionTitle";
import "./ProductCertificationDisplay.css";

export const ProductCertificationsDisplay = ({ certificates, googleAnalyticsContext }) => {
    const language = useContext(Language);

    return (
        <div>
            <SectionTitle cms="eyemark-placement" gaContext={googleAnalyticsContext}>
                {language.translateLabel("80231fa2-1275-438d-9eea-1b5918c1d461", "Certifications")}
            </SectionTitle>
            {
                <div className="sectionLabel">
                    <div className="certifications">
                        {certificates.map((cert, idx) => {
                            return (
                                <div className="description" key={"cert" + idx}>
                                    {cert.certificationTypeName}
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </div>
    );
};
